<template>
    <!-- 导航 -->
    <nav class="ui inverted attached segment m-padded-tb-mini m-shadow-small">
        <div class="ui container">
            <div class="ui inverted secondary stackable menu">
                <h2 class="ui teal header item">管理后台</h2>
                <router-link class="m-item item m-mobile-hide" active-class="teal item active" to="/admin/blogs"><i
                        class="home icon"></i>首页</router-link>
                <router-link class="m-item item m-mobile-hide" active-class="teal item active" to="/admin/types"><i
                        class="idea icon"></i>分类</router-link>
                <router-link class="m-item item m-mobile-hide" active-class="teal item active" to="/admin/tags"><i
                        class="tags icon"></i>标签</router-link>


                <div class="right m-item m-mobile-hide menu">
                    <div class="ui dropdown  item">
                        <div class="text">
                            <img src="/static/img/1.jpg" class="ui avatar image">
                            Min
                        </div>
                        <i class="dropdown icon"></i>
                        <div class="menu">
                            <a @click.prevent="logout" class="item">注销</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="#" class="ui menu toggle black icon button m-top-right m-mobile-show">
            <i class="sidebar icon"></i>
        </a>
    </nav>
</template>

<script>
import cookie from 'js-cookie'
export default {
    mounted() {
        setTimeout(() => {
            this.init()
        }, 300)
        // $('.ui.dropdown').dropdown({
        //     on: 'hover'
        // });
    },
    methods: {
        init() {
            this.$nextTick(() => {
                this.shit()
            })
        },
        logout() {
            cookie.remove('token')
            this.$router.replace('/login')
        },
        shit() {
            if (!$('.ui.dropdown').dropdown) {
                // console.log("import");
                const script = document.createElement('script');
                script.src = '/static/js/semantic.js';
                script.onload = () => {

                    $('.ui.dropdown').dropdown({
                        on: 'hover'
                    });
                };


                document.body.appendChild(script);
            } else {
                $('.ui.dropdown').dropdown({
                    on: 'hover'
                });
            }
        }
    }
}
</script>

<style>
</style>