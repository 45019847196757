import request from '@/utils/request'

export default {

    indexlist(page, limit) {
        return request({
            url: `/api/blog/list/${page}/${limit}`,
            method: 'get'
        })
    },
    reclist() {
        return request({
            url: `/api/blog/reclist`,
            method: 'get'
        })
    },
    getblog(id) {
        return request({
            url: `/api/blog/get/${id}`,
            method: 'get'
        })
    },
    search(page, limit, keyword) {
        return request({
            url: `/api/blog/search/${page}/${limit}/${keyword}`,
            method: 'get'
        })
    }

}