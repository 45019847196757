<template>
  <MyHeader v-if="showindex" />
  <MyAdminHeader v-else />
  <router-view></router-view>
  <MyFooter />
</template>

<script>
import MyHeader from '@/components/header'
import MyFooter from '@/components/footer'
import MyAdminHeader from '@/components/admin/adminheader.vue'
export default {
  name: 'App',
  components: {
    MyAdminHeader,
    MyHeader,
    MyFooter,

  },
  mounted() {

  },
  computed: {
    showindex() {
      return this.$route.fullPath.indexOf('admin') == -1;
    }
  }
}
</script>

<style>
</style>
