import axios from 'axios'
import cookie from 'js-cookie'
import router from '@/router'
// 创建axios实例
const service = axios.create({
  baseURL: '',
  timeout: 15000 // 请求超时时间
})

// http request 拦截器
service.interceptors.request.use(
  config => {
    if (cookie.get('token')) {
      config.headers['token'] = cookie.get('token')
    }
    // console.log("token======>", cookie.get('token'));
    return config
  },
  err => {
    return Promise.reject(err)
  })
// http response 拦截器
service.interceptors.response.use(
  response => {
    //状态码是208弹出登录框
    if (response.data.code === 208) {
      router.replace('/login')
      return
    } else {
      // if (response.data.code !== 200) {
      //   return Promise.reject(response.data)
      // } else {
      return response.data

      // }
    }
  },
  error => {
    return Promise.reject(error.response)
  })
export default service