<template>
  <!-- 导航 -->
  <nav class="ui inverted attached segment m-padded-tb-mini">
    <div class="ui container">
      <div class="ui inverted secondary stackable menu">
        <h2 class="ui teal header item">Blog</h2>
        <router-link class="m-item item " :class="{ 'm-mobile-show': isShow, 'm-mobile-hide': !isShow }"
          active-class="teal active" to="/index"><i class="home icon"></i>首页</router-link>
        <router-link class="m-item item " :class="{ 'm-mobile-show': isShow, 'm-mobile-hide': !isShow }"
          active-class="teal active" to="/types"><i class="idea icon"></i>分类</router-link>
        <router-link class="m-item item " :class="{ 'm-mobile-show': isShow, 'm-mobile-hide': !isShow }"
          active-class="teal active" to="/tags"><i class="tags icon"></i>标签
        </router-link>
        <router-link class="m-item item" :class="{ 'm-mobile-show': isShow, 'm-mobile-hide': !isShow }"
          active-class="teal active" to="/archives"><i class="info icon"></i>归档</router-link>
        <router-link class="m-item item " :class="{ 'm-mobile-show': isShow, 'm-mobile-hide': !isShow }"
          active-class="teal active" to="/about"><i class="info icon"></i>关于我</router-link>
        <div class="right item " :class="{ 'm-mobile-show': isShow, 'm-mobile-hide': !isShow }">
          <div class="ui icon input">
            <input type="text" placeholder="Search..." v-model="keyword" />
            <i class="search link icon" @click="search()"></i>
          </div>
        </div>
      </div>
    </div>
    <a @click.prevent="show()" class="ui menu  black icon button m-top-right m-mobile-show">
      <i class="sidebar icon"></i>
    </a>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
      isShow: false
    }
  },
  mounted() {
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
    $(window).resize(() => {          //当浏览器大小变化时
      // alert($(window).height());          //浏览器时下窗口可视区域高度
      // alert($(document).height());        //浏览器时下窗口文档的高度
      // alert($(document.body).height());   //浏览器时下窗口文档body的高度
      // alert($(document.body).outerHeight(true)); //浏览器时下窗口文档body的总高度 包括border padding margin
      if ($(window).width() >= 500) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    });
  },
  methods: {
    search() {

      if (this.$route.fullPath.indexOf('/search') != -1) {
        window.search(this.keyword)
      } else {
        this.$router.replace('/search/' + this.keyword)
      }
    },
    show() {
      this.isShow = !this.isShow
    }
  }


}
</script>

<style>
</style>