// history模式
import {
    createRouter,
    createWebHistory
} from 'vue-router'
import cookie from 'js-cookie'

const routes = [
    // 路由的默认路径
    {
        path: '/',
        redirect: "/index",
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('@/components/index/index.vue')
    },
    {
        path: '/blog/:id',
        name: 'Blog',
        component: () => import('@/components/blog/blog.vue')
    },
    {
        path: '/types',
        name: 'Type',
        component: () => import('@/components/type/type.vue')
    },
    {
        path: '/tags',
        name: 'Tag',
        component: () => import('@/components/tag/tag.vue')
    },
    {
        path: '/archives',
        name: 'Archives',
        component: () => import('@/components/myarchives/archives.vue')
    },
    {
        path: '/search/:keyword',
        name: 'Search',
        component: () => import('@/components/search/search.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/components/about/about.vue')
    },
    {
        path: '/admin',
        name: 'Name',
        redirect: '/admin/blogs',
        children: [{
                path: '/admin/blogs',
                name: 'admin-blogs',
                component: () => import('@/components/admin/blogs/blogs.vue')
            },
            {
                path: '/admin/types',
                name: 'admin-types',
                component: () => import('@/components/admin/types/types.vue')
            },
            {
                path: '/admin/tags',
                name: 'admin-tags',
                component: () => import('@/components/admin/tags/tags.vue')
            },
            {
                path: '/admin/blog-input',
                name: 'admin-blog-input',
                component: () => import('@/components/admin/blog-input.vue')
            },
            {
                path: '/admin/input/:type',
                name: 'admin-type-tag-input',
                component: () => import('@/components/admin/input.vue')
            },

        ]
    },
    {
        path: '/login',
        name: 'admin-login',
        component: () => import('@/components/admin/login.vue')
    }, {
        path: '/show',
        name: 'admin-show',
        component: () => import('@/components/admin/EditorMarkdown.vue')
    },
    {
        path: '/:error*', // 会匹配所有路径
        name: '404',
        component: () => import('@/components/404.vue')
    }

]

// 创建路由对象
const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    // console.log(to.fullPath)
    if (to.fullPath.indexOf("admin") != -1) {
        let token = cookie.get('token')
        if (token) {
            next()
        } else {
            // console.log(router);
            router.replace("/login");
        }
    }
    // console.log(from)
    next()
})
export default router;